<template>
  <div class="body-box">
    <div style="margin-bottom: 10px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/Empowerment/pledge/pledgeManage/taskManageNew' }"><span
            style="color: #bfbfbf">任务管理</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span style="color: #018bff">任务详情 </span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main-content">
      <div class="top-box">
        <div class="status green" v-if="state === '1'">已完成</div>

        <div class="status blue" v-else>处理中</div>

        <div class="title">{{ taskName }}</div>
      </div>

      <div class="tips">流水号:{{ serialNumber }}</div>
      <div class="bit-group">
        <div class="bit" :class="{ 'bit-active': bitType == item.id }" @click="changeBit(item)" v-for="item in bitGroup"
          v-show="item.count > 0" :key="item.id">
          {{ item.name }}
        </div>
      </div>
      <div class="card-box" v-show="bitType === '1'">
        <div class="card">
          <div class="card-title">已出值</div>
          <div class="card-line"></div>
          <div class="card-num">{{ finishHouseCnt }} <span>套</span></div>
        </div>
        <div class="card card-right">
          <div class="card-title">总估值</div>
          <div class="card-line"></div>
          <div class="card-num">{{ totalValuation }} <span>万元</span></div>
        </div>
      </div>
      <Row :gutter="20" style="margin-top: 28px">
        <Col span="20">
        <Form ref="searchParam" :model="searchParam" label-position="right" :label-width="70" inline>
          <FormItem label="城市" :label-width="70" style="margin-right: 28px">
            <Select v-model="searchParam.cityFullName" clearable style="width: 200px">
              <Option v-for="item in cityList" :value="item" :key="item">
                {{ item }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="房屋地址" :label-width="70" style="margin-right: 28px">
            <Input type="text" maxlength="50" style="width: 200px" clearable v-model="searchParam.houseAddress"
              placeholder="请输入">
            </Input>
          </FormItem>
          <FormItem label="处理结果" v-if="bitType === ''" :label-width="70" style="margin-right: 28px">
            <Select v-model="searchParam.resultType" @on-change="handleResultType" clearable style="width: 200px">
              <Option v-for="item in resultTypeList" :value="item.id" :key="item.id">
                {{ item.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label-width="12">
            <Button type="primary " @click="search">查询</Button>
          </FormItem>
        </Form>
        </Col>
        <Col span="4" style="display: flex; justify-content: flex-end">
        <Button type="primary" @click="downResult" :disabled="downResultDisabled">下载结果
        </Button>
        </Col>
      </Row>
      <!-- <Row :gutter="6" type="flex" justify="end" style="margin-bottom: 24px; ">
        <div>
          <Button type="primary" @click="downResult" :disabled="downResultDisabled">下载结果 </Button>
        </div>
      </Row> -->
      <Row :gutter="20">
        <Col :span="24">
        <Table :columns="columns" :data="tableData" :no-data-text="noTableText" v-loading="isLoading">
          <template slot-scope="{ row }" slot="houseAddress">
            <Tooltip max-width="400" :transfer="true">
              <!-- <div class="taskName-box" :style="{ color: row.resultType === '3' ? '#515a6e' : '#0077FF' }"
                @click="handleDetails(index, row)">
                {{ row.houseAddress }}
              </div> -->
              <div class="taskName-box" :style="{ color: houseAddressColor(row) }" @click="handleDetails(index, row)">
                {{ row.houseAddress }}
              </div>
              <!-- <div class="taskName-box" :style="{ color:'#515a6e' }" @click="handleDetails(index, row)">
                {{ row.houseAddress }}
              </div> -->
              <div slot="content">
                <div>{{ row.houseAddress }}</div>
              </div>
            </Tooltip>
          </template>
        </Table>
        </Col>
      </Row>
      <Row :gutter="16" style="margin-top: 24px" type="flex" justify="flex-start">
        <el-pagination :current-page="pageable.current" :page-size="pageable.size" :hide-on-single-page="isShowPage"
          background layout="prev, pager, next, jumper" :total="pageable.total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </Row>
    </div>
  </div>
</template>
<script>
export default {
  name: "taskDetail",
};
</script>
<script setup>
import {
  reactive,
  ref,
  computed,
  getCurrentInstance,
  onActivated,
  watch,
  onDeactivated,
} from "vue";
import {
  batchTaskDetail,
  delEvaluationHistory,
  outputStatistics,
  taskDetailExport,
  queryProcessResult,
  taskDetailCityName,
} from "@/api/pledge";
import { toCurrency } from "@/utils/";
import { useTable } from "@/hooks/useTable";
import { taskColumns } from "./taskDetailColumns";
import { useStore, useRouter, useRoute } from "@/utils/vueApi.js";
import util from "@/utils/utils2";
const { proxy } = getCurrentInstance();

const router = useRouter();
const route = useRoute();
let taskId = ref(useRoute().query.id);
let serialNumber = ref(useRoute().query.serialNumber);
let taskName = ref(useRoute().query.taskName);
let state = ref(useRoute().query.state);
const bitType = ref("");

onActivated(() => {
  if (!route.meta.keepAlive) {
    taskId.value = useRoute().query.id;
    bitType.value = "";
    serialNumber.value = useRoute().query.serialNumber;
    taskName.value = useRoute().query.taskName;
    state.value = ref(useRoute().query.state);
    initParam.taskId = taskId.value;
    initParam.serialNumber = serialNumber.value;
    initParam.taskName = taskName.value;
    initParam.resultType = "";

    getTopNum();
    reset();
    getOutputStatistics();
    gettaskDetailCityName();
  }
});
//vue3缓存销毁
onDeactivated(() => {
  //隐藏所有的tooplitp
  let tooltipDom = document.getElementsByClassName("ivu-tooltip-popper");
  // let tooltipDom = document.getElementsByClassName('ivu-tooltip-content')
  for (let i = 0; i < tooltipDom.length; i++) {
    tooltipDom[i].style.display = "none";
  }
});
// taskId = 32
const loading = ref(true);
//---------------顶部按钮切换-----------------
if (useRoute().query.bitType) {
  bitType.value = useRoute().query.bitType;
}
const modelName = ref("任务详情（全部）");
const changeBit = (item) => {
  bitType.value = item.id;
  modelName.value = item.modelName;

  if (item.name == '全部') {
    initParam.resultType = resultType.value;
  } else {
    initParam.resultType = item.id;
  }

  search();
};
//按钮组
const bitGroup = ref([
  {
    name: "全部",
    id: "",
    count: 1,
    modelName: "任务详情（全部）",
  },
  {
    name: "已出值",
    id: "1",
    count: 0,
    modelName: "任务详情（已出值）",
  },
  {
    name: "暂无法估价",
    id: "2",
    count: 0,
    modelName: "任务详情（暂无法估价）",
  },
  {
    name: "解析未通过",
    id: "3",
    count: 0,
    modelName: "任务详情（解析不成功）",
  },
]);
async function getTopNum() {
  // let params = {
  //   size: 10,
  //   current: 1,
  //   resultType: '',
  //   taskId: taskId,

  // }
  // for (let i = 0; i < bitGroup.value.length; i++) {
  //   if (i > 0) {
  //     params.resultType = i + ''

  //   }
  //   let res = await batchTaskDetail(params)
  //   let total = res.total
  //   bitGroup.value[i].count = total
  // }

  let params = {
    taskId: taskId.value,
  };
  let res = await queryProcessResult(params);

  bitGroup.value[1].count = res.haveValue;
  bitGroup.value[2].count = res.notValue;
  bitGroup.value[3].count = res.unsucc;
}
getTopNum();
// const showBitGroup  =  computed(async() => {
//   let arr = await getTopNum()
//   return arr
// })

//处理结果

const resultTypeList = [
  {
    name: "已出值",
    id: "1",
    count: 0,
    modelName: "任务详情（已出值）",
  },
  {
    name: "暂无法估价",
    id: "2",
    count: 0,
    modelName: "任务详情（暂无法估价）",
  },
  {
    name: "解析未通过",
    id: "3",
    count: 0,
    modelName: "任务详情（解析不成功）",
  },
  {
    name: "正估价中",
    id: "4",
    count: 0,
    modelName: "任务详情（正估价中）",
  },

]

const resultType = ref("");
const handleResultType = (value) => {
  // console.log('item :>> ', item);
  //   resultType.value = item.id;
  resultType.value = value;
  initParam.resultType = value;
  // search();
};



//---------------获取已出值和总估值-----------------
const finishHouseCnt = ref(""); //已出值
const totalValuation = ref(""); //总估值
const getOutputStatistics = async () => {
  let params = {
    taskId: taskId.value,
  };
  let res = await outputStatistics(params);
  finishHouseCnt.value = res.finishHouseCnt;
  if (res.totalValuation === "") {
    res.totalValuation = 0;
  }

  totalValuation.value = toCurrency(res.totalValuation);
};
getOutputStatistics();
let cityList = ref([]);
//获取城市名称
const gettaskDetailCityName = () => {
  let params = {
    taskId: taskId.value,
  };
  taskDetailCityName(params).then((res) => {
    cityList.value = res;
  });
};
gettaskDetailCityName();

//---------------------跳转-----------------------

//跳转详情页
function handleDetails(index, row) {

  if (row.resultType === "3"||row.resultType === "4") {
    //解析未通过或者正估价中
    return;
  }


  router.push({
    path: "/Empowerment/pledge/pledgeManage/taskResult",
    query: {
      cityCode: row.cityCode,
      sampleHistoryId: row.historyId,
      routeName: "taskDetail",
      id: taskId.value,
      serialNumber: serialNumber.value,
      taskName: taskName.value,
      bitType: bitType.value,
    },
  });
}
//--------------表格相关-----------------
const downResultDisabled = ref(false);
//下载结果
async function downResult() {
  // let res = await taskDetailExport()
  downResultDisabled.value = true;
  setTimeout(() => {
    downResultDisabled.value = false;
  }, 1000);
  const loading = proxy.$loading({
    lock: true,
    text: "准备下载...",
  });
  // setTimeout(() => {
  //   loading.close();
  // }, 1000);
  // util.downloadFile(res)
  let params = {
    taskId: taskId.value,
    totalValuation: totalValuation.value,
    modelName: modelName.value,
    finishHouseCnt: finishHouseCnt.value,
    resultType: bitType.value,
  };
  taskDetailExport(params).then((res) => {
    // util.downloadBlod2(res);
    loading.close();

    if (res.hasOwnProperty("headers")) {
      //调用封装好的下载方法
      proxy.$msg.success({
        text: "文件正在下载中，详情请看下载列表",
        duration: 3000,
      });
      util.downloadBlod(res);
    } else {
      //将错误信息返回
      let blob = new Blob([res]);
      blob.text().then((res) => {
        // proxy.$msg.error({ text: res });
      });
    }
  });
}
//表头
const { columns0, columns1, columns2, columns3 } = taskColumns();
const columns = computed(() => {
  switch (bitType.value) {
    case "":
      return columns0;
    case "1":
      return columns1;
    case "2":
      return columns2;
    case "3":
      return columns3;
    default:
      break;
  }
});
const houseAddressColor = (row) => {
  let text = "";
  let ajustmentColumnNameList = row.ajustmentColumnNameList;
  let resultType = row.resultType;
  if (ajustmentColumnNameList.includes("houseAddress") && resultType !== '4') {
    text = "red";
    return text;
  }
  if (row.resultType === "3" ||row.resultType === "4") {
    text = "#515a6e";
    return "#515a6e";
  } else {
    text = "#0077FF";
    return text;
  }
};
const dataCallBack = (data) => {
  data.records.forEach((item, index) => {
    item.index = index + 1 + data.current * 10 - 10;
    if (item.evaluatePrice === "" || item.evaluatePrice == 0) {
      item.evaluatePrice = "--";
    } else {
      item.evaluatePrice = (item.evaluatePrice * 1).toFixed(0);
      item.evaluatePrice = toCurrency(item.evaluatePrice);
    }
    if (item.evaluateTotalPrice === "" || item.evaluateTotalPrice == 0) {
      item.evaluateTotalPrice = "--";
    } else {
      item.evaluateTotalPrice = (item.evaluateTotalPrice * 1).toFixed(2);
      item.evaluateTotalPrice = toCurrency(item.evaluateTotalPrice);
    }

    item.cellClassName = {
      evlTitle: "demo-table-info-cell-blue",
    };
    //  item.ajustmentColumnNameList =[ ...item.ajustmentColumnNameList]
    //字符转数组
    item.ajustmentColumnNameList = item.ajustmentColumnNameList.split(",");
    item.ajustmentColumnNameList.forEach((key) => {
      if (item.resultType!=="4") {
      item.cellClassName[key] = "demo-table-info-cell-red";

      }
    });
    return item;
  });
  return data;
};
const initParam = {
  resultType: bitType.value,
  taskId: taskId.value,
};
//
// const tableLoading = ref(true)

const {
  tableData,
  pageable,
  searchParam,
  searchInitParam,
  isShowPage,
  getTableList,
  search,
  reset,
  handleSizeChange,
  handleCurrentChange,
  isLoading,
} = useTable(batchTaskDetail, initParam, true, dataCallBack);
const noTableText = computed(() => {
  if (isLoading.value) {
    return "加载中...";
  } else {
    return "暂无数据";
  }
});
</script>

<style lang="scss" scoped>
.body-box {
  background: #eff1f6;
  // height: 100%;
  min-height: 100%;
  padding: 24px;
  min-width: 1200px;

  .main-content {
    background: #ffffff;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 24px;
    min-height: 100vh;

    // min-height: 670px;
    .top-box {
      display: flex;
      margin-bottom: 16px;

      .status {
        width: 58px;
        height: 24px;
        border-radius: 4px;
        opacity: 1;
        box-sizing: border-box;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        text-align: center;
        margin-right: 12px;
      }

      .blue {
        color: #0077ff;
        border: 1px solid #82c1f9;
        background: #ddedff;
      }

      .green {
        background: #dff5eb;
        color: #00b470;
        border: 1px solid #7bdfb9;
      }

      .title {
        font-family: PingFang SC Bold;
        font-size: 18px;
        font-weight: normal;
        color: #262626;
      }
    }

    .tips {
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
      color: #737373;
      margin-bottom: 16px;
    }

    .bit-group {
      display: flex;

      .bit {
        height: 30px;
        border: #0077ff 1px solid;
        border-radius: 25px;
        line-height: 30px;
        padding: 0 20px;
        text-align: center;
        margin-right: 24px;
        color: #0077ff;
        cursor: pointer;
      }

      .bit-active {
        background: #0077ff;
        color: #ffffff;
        // border: 0;
      }
    }

    .card-box {
      display: flex;
      margin-top: 37px;
      margin-bottom: 44px;

      .card {
        //  background: url() no-repeat;
        color: #0c2362;
        border-radius: 2px;
        padding-left: 32px;
        padding-top: 32px;
        background-size: 100% 100%;
        width: 380px;
        height: 177px;
        margin-right: 50px;
        background-image: url(../../assets/image/task/card_left.png);
        box-shadow: #ebebed 2px 9px 20px -2px;

        .card-title {
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-size: 16px;
          font-weight: normal;
          // color: #262626;
          margin-bottom: 16px;
        }

        .card-line {
          height: 3px;
          border-radius: 1px;
          width: 36px;
          background: linear-gradient(92deg, #4caafc 0%, #2571f8 138%);
          margin-bottom: 10px;
        }

        .card-num {
          font-size: 48px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;

          span {
            font-size: 16px;
          }
        }
      }

      .card-right {
        background-image: url(../../assets/image/task/card_right.png);
        color: #87261a;

        .card-line {
          background: linear-gradient(90deg, #ff9e92 0%, #ff5c42 100%);
        }
      }
    }
  }

  .taskName-box {
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    // color: #0077FF;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .red-point {
      background: red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      float: left;
      position: absolute;
      left: -13px;
      top: 5px;
    }
  }
}
</style>
<style>
.ivu-table .demo-table-info-cell-red {
  color: red;
}

.ivu-table .demo-table-info-cell-blue {
  color: #0077ff;
}
</style>
