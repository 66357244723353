import {
  reactive, computed, onMounted, toRefs,
} from 'vue';
// import { Table } from './interface';

/**
 * @description table 页面操作方法封装
 * @param {Function} api 获取表格数据 api 方法(必传)
 * @param {Object} initParam 获取数据初始化参数(非必传，默认为{})
 * @param {Boolean} isPageable 是否有分页(非必传，默认为true)
 * @param {Function} dataCallBack 对后台返回的数据进行 处理的方法(非必传)
 * */
export const useTable = (
  api,
  initParam= {},
  isPageable = true,
  dataCallBack='',
) => {
  const state = reactive({
    // 表格数据
    tableData: [],
    // 分页数据
    pageable: {
      // 当前页数
      current: 1,
      // 每页显示条数
      size: 10,
      // 总条数
      total: 0,
    },
    // 查询参数(只包括查询)
    searchParam: {},
    // 初始化默认的查询参数
    searchInitParam: {},
    // 总参数(包含分页和查询参数)
    totalParam: {},
    isShowPage: true,
    isLoading: true,
  });

  /**
	 * @description 分页查询参数(只包括分页和表格字段排序,其他排序方式可自行配置)
	 * */
  const pageParam = computed({
    get: () => ({
      current: state.pageable.current,
      size: state.pageable.size,
    }),
    set: (newVal) => {
    },
  });

  // 初始化的时候需要做的事情就是 设置表单查询默认值 && 获取表格数据(reset函数的作用刚好是这两个功能)
  onMounted(() => {
    reset();
  });

  /**
	 * @description 获取表格数据
	 * @return void
	 * */
  const getTableList = async () => {
    try {
      // 先把初始化参数和分页参数放到总参数里面
       state.isLoading = true;
      Object.assign(state.totalParam, initParam, isPageable ? pageParam.value : {});
      let data = await api(state.totalParam);
      state.isLoading = false;
      // data = data.data;
      data.records.map((item,index)=>{
       item.index = (index + 1) + data.current * 10 - 10
      return item
      })
      dataCallBack && (data = dataCallBack(data));
      state.tableData = isPageable ? data.records : data;
      // 解构后台返回的分页数据 (如果有分页更新分页信息)
      const { current, size, total } = data;
      if (total > 10) {
        state.isShowPage = false;
      } else {
        state.isShowPage = true;
      }
      isPageable && updatePageable({ current, size, total });
    } catch (error) {
//
state.isLoading = false;
    }
  };

  /**
	 * @description 更新查询参数
	 * @return void
	 * */
  const updatedTotalParam = () => {
    state.totalParam = {};
    // 处理查询参数，可以给查询参数加自定义前缀操作
    const nowSearchParam = {};
    // 防止手动清空输入框携带参数（这里可以自定义查询参数前缀）
    for (const key in state.searchParam) {
      // * 某些情况下参数为 false/0 也应该携带参数
      if (state.searchParam[key] || state.searchParam[key] === false || state.searchParam[key] === 0) {
        nowSearchParam[key] = state.searchParam[key];
      }
    }
    Object.assign(state.totalParam, nowSearchParam, isPageable ? pageParam.value : {});
  };

  /**
	 * @description 更新分页信息
	 * @param {Object} resPageable 后台返回的分页数据
	 * @return void
	 * */
  const updatePageable = (resPageable) => {
    Object.assign(state.pageable, resPageable);
  };

  /**
	 * @description 表格数据查询
	 * @return void
	 * */
  const search = () => {
   state.isLoading = true;
    state.pageable.current = 1;
    state.pageable.size = 10;
    updatedTotalParam();
    getTableList();
  };

  /**
	 * @description 表格数据重置
	 * @return void
	 * */
  const reset = () => {
   state.isLoading = true;

    state.pageable.current = 1;
    state.searchParam = {};
    // 重置搜索表单的时，如果有默认搜索参数，则重置默认的搜索参数
    Object.keys(state.searchInitParam).forEach((key) => {
      state.searchParam[key] = state.searchInitParam[key];
    });
    updatedTotalParam();
    getTableList();
  };

  /**
	 * @description 每页条数改变
	 * @param {Number} val 当前条数
	 * @return void
	 * */
  const handleSizeChange = (val) => {
    state.pageable.current = 1;
    state.pageable.size = val;
    getTableList();
  };

  /**
	 * @description 当前页改变
	 * @param {Number} val 当前页
	 * @return void
	 * */
  const handleCurrentChange = (val) => {
    state.pageable.current = val;
    getTableList();
  };

  return {
    ...toRefs(state),
    getTableList,
    search,
    reset,
    handleSizeChange,
    handleCurrentChange,
  };
};
