import { render, staticRenderFns } from "./taskDetailNew.vue?vue&type=template&id=50076fd6&scoped=true&"
import script from "./taskDetailNew.vue?vue&type=script&setup=true&lang=js&"
export * from "./taskDetailNew.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./taskDetailNew.vue?vue&type=style&index=0&id=50076fd6&prod&lang=scss&scoped=true&"
import style1 from "./taskDetailNew.vue?vue&type=style&index=1&id=50076fd6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50076fd6",
  null
  
)

export default component.exports