import { ref } from "vue"
import {
    formatTime,
    toCurrency
} from "@/utils/";
export const taskColumns = () => {
    const columns0 = ref([{
        title: '序号',
        key: "index",

        width: 79,
    },
    {
        title: '城市',
        key: 'cityFullName',
        width: 96,
        tooltip: true
    },
    {
        title: '房屋地址',
        key: 'houseAddress',
        slot: 'houseAddress',
        width: 305,
        tooltip: true,


    },
    {
        title: '小区名称',
        key: 'lpName',
        width: 191,
        tooltip: true
    },
    {
        title: '面积',
        key: 'houseArea',
        width: 150,
        tooltip: true
    },
    {
        title: '单价（元/㎡） ',
        key: "evaluatePrice",
        width: 150,
        render: (h, params) => {
            let text = ''
            switch (params.row.assessResult) {
                case 1:
                    text = params.row.evaluatePrice
                    break;
                case 3:
                    if (params.row.evaluatePrice == "--") {
                        text = params.row.evaluatePrice
                    } else {
                        text = params.row.evaluatePrice + '(' + '参考价' + ')'
                    }

                    break;
                default:
                    text = params.row.evaluatePrice
                    break;
            }
            return h(
                "span",
                text
            );
        },
    },

    {
        title: "总价（万元）",
        key: "evaluateTotalPrice",
        width: 150,
        render: (h, params) => {
            let text = ''
            switch (params.row.assessResult) {
                case 1:
                    text = params.row.evaluateTotalPrice
                    break;
                case 3:
                    if (params.row.evaluateTotalPrice == "--") {
                        text = params.row.evaluateTotalPrice
                    } else {
                        text = params.row.evaluateTotalPrice + '(' + '参考价' + ')'
                    }
                    break;
                default:
                    text = params.row.evaluateTotalPrice
                    break;
            }
            return h(
                "span",
                text
            );
        },
    },
    {
        title: '估价基准日',
        key: 'evaluateBasicDate',
        width: 140,
        tooltip: true
    },
    {
        title: "提交时间",
        key: "createTime",
        width: 180,

        render: (h, params) => {
            return h(
                "span",
                params.row.createTime ? formatTime(params.row.createTime, '/') : formatTime(
                    params.row.gmtUpdated, '/')
            );
        },
    },
    {
        title: '处理结果',
        key: 'resultType',
        // width: 127,
        minWidth: 127,

        //处理结果：1，已出值 2.暂无法估价 3.解析未通过
        render: (h, params) => {
            let text = ''
            switch (params.row.resultType) {
                case '1':
                    text = '已出值'
                    break;
                case '2':
                    text = '暂无法估价'
                    break;
                case '3':
                    text = '解析未通过'
                    break;
                case '4':
                    text = '正估价中'
                    break;
                default:
                    break;
            }
            return h(
                "span",
                text
            );
        }
    },


    ])
    const columns1 = ref([{
        title: '序号',
        key: "index",
        width: 79,
    },
    {
        title: '城市',
        key: 'cityFullName',
        width: 96,
        tooltip: true
    },
    {
        title: '房屋地址',
        key: 'houseAddress',
        slot: 'houseAddress',

        width: 310,
        tooltip: true,

    },
    {
        title: '小区名称',
        key: 'lpName',
        width: 191,
        tooltip: true
    },
    {
        title: '面积',
        key: 'houseArea',
        width: 150,
        tooltip: true
    },
    {
        title: '单价（元/㎡） ',
        key: "evaluatePrice",
        width: 180,
        render: (h, params) => {
            let text = ''
            switch (params.row.assessResult) {
                case 1:
                    text = params.row.evaluatePrice
                    break;
                case 3:
                    if (params.row.evaluatePrice == "--") {
                        text = params.row.evaluatePrice
                    } else {
                        text = params.row.evaluatePrice + '(' + '参考价' + ')'
                    }

                    break;
                default:
                    text = params.row.evaluatePrice
                    break;
            }
            return h(
                "span",
                text
            );
        },
    },

    {
        title: "总价（万元）",
        key: "evaluateTotalPrice",
        width: 180,
        render: (h, params) => {
            let text = ''
            switch (params.row.assessResult) {
                case 1:
                    text = params.row.evaluateTotalPrice
                    break;
                case 3:
                    if (params.row.evaluateTotalPrice == "--") {
                        text = params.row.evaluateTotalPrice
                    } else {
                        text = params.row.evaluateTotalPrice + '(' + '参考价' + ')'
                    }
                    break;
                default:
                    text = params.row.evaluateTotalPrice

                    break;
            }
            return h(
                "span",
                text
            );
        },
    },
    {
        title: '估价基准日',
        key: 'evaluateBasicDate',
        width: 180,
        tooltip: true
    },
    {
        title: "提交时间",
        key: "createTime",
        // width: 200,
        minWidth: 200,

        render: (h, params) => {
            return h(
                "span",
                params.row.createTime ? formatTime(params.row.createTime, '/') : formatTime(
                    params.row.gmtUpdated, '/')
            );
        },
    },


    ])
    const columns2 = ref([{
        title: '序号',
        key: "index",
        width: 77,
    },
    {
        title: '城市',
        key: 'cityFullName',
        width: 100,
        tooltip: true
    },
    {
        title: '房屋地址',
        key: 'houseAddress',
        slot: 'houseAddress',

        width: 300,
        tooltip: true,

    },
    {
        title: '小区名称',
        key: 'lpName',
        width: 185,
        tooltip: true
    },
    {
        title: '面积',
        key: 'houseArea',
        width: 150,
        tooltip: true
    },
    {
        title: '单价（元/㎡） ',
        key: "evaluatePrice",
        width: 180,
        render: (h, params) => {
            let text = ''
            switch (params.row.assessResult) {
                case 1:
                    text = params.row.evaluatePrice
                    break;
                case 3:
                    if (params.row.evaluatePrice == "--") {
                        text = params.row.evaluatePrice
                    } else {
                        text = params.row.evaluatePrice + '(' + '参考价' + ')'
                    }

                    break;
                default:
                    text = params.row.evaluatePrice
                    break;
            }
            return h(
                "span",
                text
            );
        },
    },

    {
        title: "总价（万元）",
        key: "evaluateTotalPrice",
        width: 180,
        render: (h, params) => {
            let text = ''
            switch (params.row.assessResult) {
                case 1:
                    text = params.row.evaluateTotalPrice
                    break;
                case 3:
                    if (params.row.evaluateTotalPrice == "--") {
                        text = params.row.evaluateTotalPrice
                    } else {
                        text = params.row.evaluateTotalPrice + '(' + '参考价' + ')'
                    }
                    break;
                default:
                    text = params.row.evaluateTotalPrice

                    break;
            }
            return h(
                "span",
                text
            );
        },
    },
    {
        title: '估价基准日',
        key: 'evaluateBasicDate',
        width: 180,
        tooltip: true
    },
    {
        title: "提交时间",
        key: "createTime",
        // width: 215,
        minWidth: 215,

        render: (h, params) => {
            return h(
                "span",
                params.row.createTime ? formatTime(params.row.createTime, '/') : formatTime(
                    params.row.gmtUpdated, '/')
            );
        },
    },

    ])
    const columns3 = ref([{
        title: '序号',
        key: "index",
        width: 103,
    },
    {
        title: '城市',
        key: 'cityFullName',
        width: 200,
        tooltip: true
    },
    {
        title: '房屋地址',
        key: 'houseAddress',
        slot: 'houseAddress',

        width: 430,
        tooltip: true,
        // slot: 'evlTitle',

    },
    {
        title: '小区名称',
        key: 'lpName',
        width: 370,
        tooltip: true
    },
    {
        title: '面积',
        key: 'houseArea',
        width: 230,
        tooltip: true
    },
    {
        title: '原因',
        key: 'analysisType',
        // width: 235,
        minWidth: 235,

        render: (h, params) => {
            let text = ''
            switch (params.row.analysisType) {
                case 2:
                    text = '字段校验未通过'
                    break;
                case 3:
                    text = '地址解析未通过'
                    break;
                case 4:
                    text = '城市未上线或无权限'
                    break;
                default:
                    text = ''
                    break;
            }
            return h(
                "span",
                text
            );
        },
        renderHeader: (h, params) => {
            return h(
                "Tooltip", {
                props: {
                    content: `1、字段校验未通过：城市或面积等录入格式非系统格式，如：面积录入为非数字。
2、地址解析未通过：如输入商业、办公、厂房、城中村等非商品住宅，地址录入错误，城市和地址不匹配等。
3、城市未上线或无权限：系统未上线该城市，或您未开通该城市的权限。
4、系统自动将解析未成功的字段标为红色
       `,
                    placement: 'left',
                    maxWidth: 360,
                    transfer: true
                },
            },
                [
                    h("span", "原因"),
                    h("Icon", {
                        props: {
                            type: "ios-help-circle-outline",
                            size: 20,
                            style: {
                                color: "#999",
                                marginLeft: "5px"
                            }
                        }
                    })
                ]
            );
        },
    },
    ])

    return {
        columns0,
        columns1,
        columns2,
        columns3,
    }
}